@font-face {
  font-family: "Proxima-Nova-Black";
  src: url("../font/PROXIMANOVA-BLACK-WEBFONT.TTF") format(".TTF");
}

@font-face {
  font-family: "Proxima Nova Bold";
  src: url("../font/PROXIMANOVA-BOLD-WEBFONT.TTF") format(".TTF");
}

@font-face {
  font-family: "Proxima Nova Light";
  src: url("../font/PROXIMANOVA-LIGHT-WEBFONT.TTF") format(".TTF");
}

@font-face {
  font-family: "Proxima Nova Regular";
  src: url("../font/PROXIMANOVA-REG-WEBFONT.TTF") format(".TTF");
}

@font-face {
  font-family: "Proxima Nova Semibold";
  src: url("../font/PROXIMANOVA-SBOLD-WEBFONT.TTF") format(".TTF");
}

@font-face {
  font-family: "Proxima-Nova-Thin";
  src: url("../font/PROXIMANOVA-THIN-WEBFONT.TTF") format(".TTF");
}

@font-face {
  font-family: "Proxima-Nova-Extrabold";
  src: url("../font/PROXIMANOVA-XBOLD-WEBFONT.TTF") format(".TTF");
}

@font-face {
  font-family: "Proxima-Nova-Alt-Bold";
  src: url("../font/Proxima-Nova-Alt-Bold.otf") format(".otf");
}

@font-face {
  font-family: "Proxima-Nova-Alt-Thin";
  src: url("../font/Proxima-Nova-Alt-Thin.otf") format(".otf");
}

body {
  font-family: "Proxima Nova Semibold", sans-serif;
  color: #ffffff;
  overflow-x: hidden !important;
}

/* ***********************************SideBar **********************************************/
.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  background-color: transparent;
}

.sidebar-col {
  padding-left: 0px !important;
  text-align: center;
  padding-right: 0px !important;
}

.header-row {
  height: 72px;
}

.sidebar {
  width: 6%;
  min-height: 100vh;
  background-color: #eac8bd;
}

.sidebar-piller {
  width: 100%;
  padding-top: 20px;
  text-align: center;
  /* background-color: #eac8bd; */
}

.expandable-menu {
  width: 100%;
  height: 30px;
  cursor: pointer;
}

.sb-toggle-icon {
  font-size: 32px !important;
  color: #000000;
  cursor: pointer;
}

.icon-list {
  padding-top: 80px;
}

.sb-icon-items {
  color: rgba(0, 0, 0, 0.4);
  font-size: 15px !important;
}

.col-padding-0 {
  padding-left: 0px !important;
}

.pro-item-content {

  font-family: "Proxima Nova Semibold", sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.4);
}

.toggle-btn-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 20px;
}

.toggle-btn-div1 {
  display: flex;
  width: 100%;
  justify-content: center;
}

.padding-icon {
  padding-bottom: 40px;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
  position: relative;
  background-color: antiquewhite !important;

}

.side-bar-submenu:hover {}

@media (max-width: 768px) {
  .app .btn-toggle {
    display: flex;
  }
}

.app .btn-toggle {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: #353535;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  display: none;
}

/************************************* End *************************************************/
/************************************* Header *************************************************/
.header-main-div {
  /* height: 100vh; */

  width: 100%;

}

.navbar-header {
  width: 100%;
  background-color: #ffffff;
  height: 72px;
  padding: 0 40px;

}

.page-title-icon {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
}

.nav-header-data {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

}

.header-page-title {
  font-family: "Proxima Nova", sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0;
  color: #000000;
}

.page-title {
  display: flex;
  width: 100%;
}

.header-back-icon {
  color: #000000;
  font-size: 16px !important;
  cursor: pointer;
}

.header-search {
  width: 75% !important;
  height: 48px !important;
  border-radius: 4px !important;
  border: solid 1px #d3d0d0 !important;
  background-color: #ffffff !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px !important;
  font-weight: 300 !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000 !important;
  padding-left: 30px !important;
}

.user-profile {
  height: 75%;
  cursor: pointer;
}

.user-options {
  display: block;
  background: #fff;
  position: absolute;
  z-index: 9999 !important;
  width: 86% !important;

  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.13);
  padding: 10%;
}

#frmUpload {
  display: none;
  border: 0px;
}

.user-name-div {
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  color: #212529;
}

.user-action-div {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #212529;
}

.user-icon {
  margin-right: 20px;
}

.search-col {
  display: flex !important;
  padding-top: 1%;
  /* align-items: center; */
}

.search-col-img {
  display: flex !important;
  align-items: center;
}

.searchBar {
  width: 100%;
  position: relative;
}

.search-icon-header {
  position: absolute;
  top: 18px !important;
  left: 10px;
  color: #d3d0d0;
}

.flex-end {
  justify-content: flex-end;
  display: flex !important;
  width: 100%;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-left {
  width: 100%;
  display: flex;
  justify-content: left
}

.btn .btn-link:active,
.btn .btn-link:focus {
  border: 0px !important;
}

/************************************* End *************************************************/
/* **********************Common********************************* */
.crm-body {
  background-color: #f7f8fc;
  min-height: 80vh;
  width: 100%;
}

.action-div {
  width: 100%;
  height: 64px;
  background-color: #ecedf3;
  display: flex;
  align-items: center;
  padding: 0px 48px;
}

.add-button {
  width: 100%;
  height: 40px;
  border-radius: 4px !important;
  border: solid 1px #d6d6d6 !important;
  background-color: #6e9e75 !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #ffffff;
  border: 0px !important;
}

.filter-button {
  width: 113px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d6d6d6 !important;
  background-color: #ffffff !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000000 !important;
}

.btn-div {
  margin-right: 16px;
  width: 100% !important;
}

.SortByButton {
  border: solid 1px #d6d6d6;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.4);
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;

  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

.searchDropdownDiv {
  width: 100%;
}

.selectSearch {
  border: solid 1px #d6d6d6;
  border-left: 0px;
  background-color: #ffffff;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  width: 70%;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

/************************************* End *************************************************/
/************************************* Leade *************************************************/
.lead-table-div {
  padding: 56px 40px;

}

.home-filter-container {
  width: 100%;
}

.login-container {
  width: 80%;
  margin: 0 auto;
}

.table-head-lead {
  font-family: "Proxima Nova", sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
  text-align: left;
}

.lead-table-data {
  padding-top: 24px;
}

.lead-show-entry {
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000;
  text-align: left;
}

/* .lead-table-div{
  padding: 56px 0px;
} */
.hide-mob {
  display: block;
}

.home-filter-container {
  width: 90%;
  margin: 0 auto;
  /* padding-left: 15px; */
}

.table-items {

  height: 130px;
  border-radius: 8px;
  background-color: #ffffff;

}

.search-icon-lead {
  cursor: pointer;
}

.table-data {
  padding: 17px 0px;
  width: 100%;
  word-break: break-all;
  cursor: pointer;
  overflow: hidden;
}

.check-box-lead {
  width: 14px;
  height: 14px;
  border-radius: 4px;
  border: solid 1px #979797;
  background-color: #ffffff;
}

.lead-data {
  font-family: "Proxima Nova", sans-serif;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  text-align: left;
  /* display: flex; */
}
.lead-data-date{
  font-family: "Proxima Nova", sans-serif;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  text-align: left;
  width: 100%;
}

.pending {
  color: #d0021b;
}

.lead-action-select {
  width: 96px;
  height: 28px;
  border-radius: 4px;
  border: solid 1px #d8d6d6;
  background-color: #ffffff;
  font-family: "Proxima Nova", sans-serif;
  font-size: 12px !important;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
  /* -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 100%; */
}

.action-icon-list {
  display: block !important;
}

/* .lead-action-select{
 
       
        background-position-y: 5px;
        border: 1px solid #dfdfdf;
        border-radius: 2px;
        margin-right: 2rem;
        /* padding: 1rem;
        padding-right: 2rem; */

.lead-action-video {
  font-size: 18px;
  color: #4a90e2;
}

.action-list-whatsapp {
  height: 28px;

}

.icon-padding {
  margin-right: 8px;
  cursor: pointer;

}

.order-col {
  display: flex;
}

.margin-5 {
  margin-left: 10px;
  /* width: 50%; */
}

#tooltip-top>.tooltip-inner {
  height: 30px;
  border-radius: 4px;
  background-color: #e1edfc;
  font-size: 10px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
  font-family: "Proxima Nova", sans-serif;
  display: flex;
  align-items: center;
}

#tooltip-top>.tooltip-arrow {
  border-top: 5px solid #e1edfc;
  background-color: #e1edfc;
}

/************************************* End *************************************************/
/************************************* Check Box *************************************************/
.chk-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.chk-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-form {
  position: absolute;
  top: 2px;
  left: 15px;
  width: 14px;
  height: 14px;
  border-radius: 4px;
  border: solid 1px #979797;
  background-color: #ffffff;
}

/* On mouse-over, add a grey background color */
.chk-container:hover input~.checkmark-form {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.chk-container input:checked~.checkmark-form {
  /* border:1px solid #2196F3; */
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-form :after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.chk-container input:checked~.checkmark-form :after {
  display: block;

}

/* Style the checkmark/indicator */
.chk-container .checkmark-form :after {
  left: 4px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/************************************* End *************************************************/
/* ***************************Alert******************************** */
/* Alert */

#oprationDiv {
  position: relative;
  ;
  height: 36px;
  width: 287px;
  border: 1px solid #EE9AA2;
  border-radius: 4px;
  background-color: #F5D5D6;
  color: #E22A40;
  font-family: "Proxima Nova", sans-serif;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16px;
  top: 10px;
  float: right;
  right: 30px;
  padding: 10px;
}

.popover>.triangle,
.popover>.triangle:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px;
  border-top-width: 0;
  border-color: transparent;
}

.popover>.triangle {
  top: -10px;
  border-bottom-color: #EE9AA2;
}

.popover>.triangle:after {
  top: 11px;
  margin: -10px;
  content: " ";
  border-bottom-color: #F5D5D6;
}

.pro-sidebar {
  position: fixed;
}

#oprationDiv>.triangle {
  right: 20px;
}

.btn-toggle {
  display: none;
  padding-left: 25px;
}

.show-mob {
  display: none !important;
}

.crm-body-col {
  padding-left: 80px !important;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  /* .header-search-input-group{
    z-index: 1 !important;
  } */
  .fontAction{
    font-size: 12px;
  }
  .btn-toggle {
    display: block;
  }
  .mob-padding{
    padding: 0px !important;
  }
  .table-items{
    height: max-content;
  }
  .show-mob {
    display: block !important;
  }

  .hide-mob {
    display: none !important;
  }

  .icon-padding {
    margin-right: 4px;
  }

  .lead-action-select {
    font-size: 12px !important;
  }

  .pro-sidebar>.pro-sidebar-inner .pro-sidebar-header {
    border-bottom: 0px !important;
  }

  .sb-toggle-icon {
    display: none
  }

  .pro-sidebar-content {
    display: none;
  }

  .sidebar {
    min-height: 0px !important;
    background-color: transparent;
  }

  .navbar-header {
    /* position: fixed; */
    top: 0px;
    padding: 0px 60px;
    height: 56px;
  }

  .crm-body-col {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

 

  .pro-sidebar.collapsed {
    position: fixed !important;
    min-width: 55px !important;
  }

  .search-col {
    height: 100%;
    width: 100%;
    align-items: center;
    display: flex;
  }

  .nav-header-data {
    margin-top: 7px;
    padding-left: 30px;
  }

  .header-search-icon {
    font-size: 16px !important;
    color: #000000 !important;
    border: 0px;
    cursor: pointer;
  }

  .header-row {
    height: 56px !important;
  }

  .user-profile {
    width: 28px;
    height: 28px;
  }

  .crm-body {
    min-height: 100vh;
  }
}

@media (max-width: 575.98px) and (min-width: 200px) {
  .lead-entry-back-button{
    top: -31px !important;
    left: 13% !important;
}


  
  /* .header-search-input-group{
    z-index: 1 !important;
  } */
  .btn-toggle {
    display: block;
  }
  .mob-padding{
    padding: 0px !important;
  }
  .table-items{
    height: max-content;
  }
  .show-mob {
    display: block !important;
  }

  .hide-mob {
    display: none !important;
  }

  .icon-padding {
    margin-right: 4px;
  }

  .lead-action-select {
    font-size: 12px !important;
  }

  .pro-sidebar>.pro-sidebar-inner .pro-sidebar-header {
    border-bottom: 0px !important;
  }

  .sb-toggle-icon {
    display: none
  }

  .pro-sidebar-content {
    display: none;
  }

  .sidebar {
    min-height: 0px !important;
    background-color: transparent ;
  }

  .navbar-header {
    /* position: fixed; */
    top: 0px;
    padding: 0px 60px;
    height: 56px !important;
 
  }
  .mob-search-row{
    z-index: 9999 !important;

  }

  .crm-body-col {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  /* .pro-sidebar {
    position: inherit !important;
  } */

  .pro-sidebar.collapsed {
    position: fixed !important;
    min-width: 55px !important;
  }
  
  .search-col {
    height: 100%;
    width: 100%;
    align-items: center;
    display: flex;
  }

  .nav-header-data {
    margin-top: 7px;
    padding-left: 30px;
  }

  .header-search-icon {
    font-size: 16px !important;
    color: #000000 !important;
    border: 0px;
    cursor: pointer;
  }

  .header-row {
    height: 56px !important;
  }

  .user-profile {
    width: 28px;
    height: 28px;
  }

  .crm-body {
    min-height: 100vh;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .lead-entry-back-button{
    top: -31px !important;
    left: 13% !important;

  }
}

@media (max-width: 991.98px) and (min-width: 768px) {}

@media (max-width: 1199.98px) and (min-width: 992px) {}

/* ***************************End********************************** */
/* ***************************Call Modal********************************** */
.call-modal {
  overflow-y: hidden !important;
}

.call-modal .modal-content {
  width: 75% !important;
  margin-left: 12.5% !important;
  border-radius: 8px !important;
  padding: 24px;

}

.call-modal .modal-header {
  border-bottom: 0px !important;
  align-self: center;
}

.make-a-call-header {
  text-align: center;
  display: flex;
}

.make-call-head {
  font-family: "Proxima Nova", sans-serif;
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}

.cm-label-name {
  font-family: "Proxima Nova", sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}

.cm-form-controls {
  height: 48px !important;
  border-radius: 4px !important;
  border: solid 1px #979797 !important;
  background-color: #ffffff !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.4) !important;
}

.cm-advance {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #6e9e75 !important;
  font-family: "Proxima Nova", sans-serif;
  margin-top: 15px;
}

.cm-btn-div {
  margin-top: 40px;
  width: 100%;
}

.cm-btn {
  height: 65px !important;
  border-radius: 4px !important;
  background-color: #6e9e75 !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff !important;
  width: 100%;
  border: 0px !important;
}

.cm-close {
  position: absolute;
  top: 5%;
  right: 5%;
  color: rgba(0, 0, 0, 0.4);
  font-size: 20px !important;
  cursor: pointer;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .call-modal .modal-content {
    width: 94% !important;
    margin-left: 3% !important;
  }

  .make-call-head {
    font-size: 24px !important;
  }

  .cm-label-name {
    font-size: 16px !important;
  }

  .cm-btn {
    height: 54px !important;
  }

}
@media (min-width: 576px) and (max-width: 767.98px) {
  .call-modal .modal-content {
    width: 94% !important;
    margin-left: 3% !important;
  }

  .make-call-head {
    font-size: 24px !important;
  }

  .cm-label-name {
    font-size: 16px !important;
  }

  .cm-btn {
    height: 54px !important;
  }

}
@media (max-width: 575.98px) and (min-width: 200px) {
  .call-modal .modal-content {
    width: 94% !important;
    margin-left: 3% !important;
  }

  .make-call-head {
    font-size: 24px !important;
  }

  .cm-label-name {
    font-size: 16px !important;
  }

  .cm-btn {
    height: 54px !important;
  }

}

/* ***************************End********************************** */
/* ***************************Footer********************************** */
.footer-row {
  height: 42px;
  background-color: #ffffff;
  display: flex;
  align-items: center;

}
.footer{
  position: sticky !important;
bottom: 1px !important;
}
.add-footer {
  width: 36px;
  height: 36px;
  box-shadow: 0 2px 4px 0 rgba(157, 157, 157, 0.5);
  background-color: #6e9e75 !important;
  border-radius: 18px !important;
  color: white;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px !important;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .filter-button {
    width: 100%;
    border-radius: 0px !important;
    border: 0px !important;
  }

  .selectSearch {
    width: 50%;
    border: 0px;
  }

  .btn-div {
    margin-right: 0px;
  }

  .SortByButton {
    border: 0px;
  }
}
@media (max-width: 575.98px) and (min-width: 200px) {
  .filter-button {
    width: 100%;
    border-radius: 0px !important;
    border: 0px !important;
  }

  .selectSearch {
    width: 50%;
    border: 0px;
  }

  .btn-div {
    margin-right: 0px;
  }

  .SortByButton {
    border: 0px;
  }
}

/* ***************************End********************************** */
/* ***************************Lead Entry********************************** */
.lead-entry {
  width: 100%;
}

.dialog-select {
  width: 200px !important;
}

.list-quantity-ctrl {
  width: 25% !important;
  margin: 0px 2%;
}

.lead-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lead-img-text-head {
  font-family: "Proxima Nova", sans-serif !important;
  font-size: 20px !important;
  font-weight: 300 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
  text-align: center !important;
}

.lead-img-browse-label:focus {
  box-shadow: none !important;
}

.lead-img-browse-label {
  font-family: "Proxima Nova", sans-serif !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
  text-align: center !important;
 
}

.padding-img-class {
  width: 100%;
  height: 100%;
  padding: 10%;
}

.form-heading {
  font-family: "Proxima Nova", sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}

/* Form */
.lead-entry-main {
  background-color: #f7f8fc;
  width: 100%;
  padding: 52px 0px;
}

.lead-entry-img-file {
  min-height: 250px;
  border-radius: 2px;
  border: solid 1px rgba(255, 255, 255, 0.34);
  background-color: #ffffff;
  width: 100%;
}

.le-minus {

  width: 50px;
  height: 40px;
  border-radius: 4px !important;
  border: solid 1px rgba(153, 153, 153, 0.34) !important;
  background-color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000 !important;
  font-family: "Proxima Nova", sans-serif;

}

.le-plus {
  width: 50px;
  height: 40px;
  border-radius: 4px !important;
  background-color: #6e9e75 !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff !important;
  border: 0px !important;
}

.crm-filter-container {
  width: 90%;
  margin: 0 auto;
}

.form-heading {
  font-family: "Proxima Nova", sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}

.upload-builk-lead {
  width: 186px;
  height: 40px;
  border-radius: 4px !important;
  border: solid 1px #6e9e75 !important;
  background-color: #ffffff !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #6e9e75 !important;
}

.le-cancel {
  width: 110px;
  height: 40px;
  border-radius: 4px !important;
  border: solid 1px rgba(153, 153, 153, 0.34) !important;
  background-color: #ffffff !important;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
  font-family: "Proxima Nova", sans-serif;
}

.le-save {
  width: 90px;
  height: 40px;
  border-radius: 4px !important;
  background-color: #6e9e75 !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff !important;
}
.lead-entry-exotel-call-btn{
  height: 40px;
  border-radius: 4px !important;
  background: crimson;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lead-details-btn{
  height: 30px !important; 
  font-size: 14px !important;
  width: max-content !important;
  color: white !important;
  padding-top: 3px !important;
}
.le-form-controls {
  height: 52px !important;
  width: 100% !important;
  border-radius: 2px !important;
  border: solid 1px rgba(151, 151, 151, 0.34) !important;
  background-color: #ffffff !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #283048 !important;
  padding-top: 25px !important;
}

.le-form-controls-date {
  height: 52px !important;
  width: 100% !important;
  border-radius: 2px !important;
  border: solid 1px rgba(151, 151, 151, 0.34) !important;
  background-color: #ffffff !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #283048 !important;
  padding-left: 15px;

}

.MuiInputBase-root p {
  position: absolute !important;
  bottom: -35px;
  top: 55px !important;
}

.react-time-picker__wrapper {
  border: 0px !important;
}

.le-form-controls-select {
  height: 52px !important;
  width: 100% !important;
  border-radius: 2px !important;
  /* border: solid 1px rgba(151, 151, 151, 0.34) !important; */
  background-color: #ffffff !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px !important;
  /* font-weight: 600 !important; */
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #283048 !important;
}

#menu-ddlVegGardenWhatPreferToPlant .MuiList-root {
  background-color: #f2f2f2 !important;
}

.le-label-multi.MuiInputLabel-outlined.MuiInputLabel-shrink {
  display: none !important;
}

.le-checkbox.MuiCheckbox-colorSecondary.Mui-checked {
  color: #fff !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #fff !important;
}

.le-options.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover,
.MuiListItem-root.Mui-selected:active,
.MuiListItem-root.Mui-selected:focus {
  background-color: #4198ff !important;
}

.le-checkbox {
  width: 14px !important;
  height: 14px !important;
  border-radius: 2px !important;
  border: 0px !important;
  border: solid 1px #4198ff !important;

}

.profile-entry-label.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(0px, -16px) scale(0.75) !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}

.select::after {
  color: rgba(0, 0, 0, 0.4);
}

.le-form-controls-ta {

  width: 100% !important;
  border-radius: 2px !important;
  border: solid 1px rgba(151, 151, 151, 0.34) !important;
  background-color: #ffffff !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #283048 !important;
  padding-top: 25px !important;
  font-weight: 600 !important;
}

textarea {
  resize: none !important;
}

.lead-entry-btn-group {
  margin-bottom: 24px;
  display: flex;
}

.lead-col {
  margin-bottom: 10px;
}

.le-nex-div {
  margin-top: 52px;
}

.personal-div {
  padding-bottom: 24px;
}

.le-up-pics {
  height: 166px;
  border-radius: 2px;
  border: solid 1px rgba(255, 255, 255, 0.34);
  background-color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.le-up-icon {
  font-size: 30px !important;
  color: #000000;

}

.le-up-pics-icon {
  text-align: center;
  padding-bottom: 20px;
}

.le-up-pic-tit-text {
  font-family: "Proxima Nova", sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
  text-align: center;
}

.le-up-pic-sub-text {
  font-family: "Proxima Nova", sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
  text-align: center;
}

.le-save-submit {
  width: 120px;
  height: 50px;
  border-radius: 4px !important;
  background-color: #6e9e75 !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 22px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff !important;
  border: 0px !important;
}
.le-email-submit {
  width: max-content;
  height: 50px;
  border-radius: 4px !important;
  background-color: #e05674 !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 22px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff !important;
  border: 0px !important;
  padding-left: 10px;
  padding-right: 10px;
}
.le-download-submit {
  width: max-content;
  height: 50px;
  border-radius: 4px !important;
  background-color: #644fbe !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 22px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff !important;
  border: 0px !important;
  padding-left: 10px;
  padding-right: 10px;
}
.le-cancel-submit {
  width: 150px;
  height: 50px;
  border-radius: 4px !important;
  border: solid 1px rgba(153, 153, 153, 0.34) !important;
  background-color: #ffffff !important;
  font-size: 22px !important;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
  font-family: "Proxima Nova", sans-serif;
}

input:focus~.floating-label {

  top: 0px !important;
  font-size: 12px;
  opacity: 1;
  color: #36454f;
  font-family: "Proxima Nova", sans-serif;
  padding: 0.5rem .75rem;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

}

textarea:focus~.floating-label,
textarea:active~.floating-label,
textarea:not([value=""])~.floating-label {
  top: 0px !important;
  font-size: 12px;
  opacity: 1;
  color: #36454f;
  font-family: "Proxima Nova", sans-serif;
  padding: 0.5rem .75rem;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

select#ddlLookPreferred option[value="Type_1"] {
  background-image: url(../images/thumbnail.jpg);
}

.le-form-controls:not([value=""])~.floating-label {
  top: 5px !important;
  position: absolute;
}


/* #txtFromNumber:focus{
  content: '+91';
} */
.floating-label {
  position: absolute;
  pointer-events: none;
  top: 18px !important;
  color: #36454f;
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  padding-left: .60rem;
  padding-bottom: 20px;

}

.mob-container {
  width: 100%;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .mob-container {
    width: 90%;
    margin: 0 auto;
  }
  .lead-img-browse-label{
    font-size: 18px !important;
  }
  .lead-img-text-head{
    font-size: 14px !important;
  }
  .le-mob-header {
    height: 54px;
    background-color: #ecedf3;
    width: 100%;
    display: flex !important;
    align-items: center;
  }

  .le-mob-header-plus-btn {
    width: 47px;
    height: 38px;
    border-radius: 4px !important;
    border: solid 1px #6e9e75 !important;
    background-color: #ffffff !important;
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    cursor: pointer !important;
    letter-spacing: normal;
    color: #6e9e75 !important;

  }

  .le-cancel,
  .le-save {
    width: 86px !important;
    height: 38px !important;
    font-size: 14px !important;
    font-weight: 300 !important;
  }

  .align-center {
    display: flex !important;
    align-items: center !important;
  }

  .form-heading {
    font-size: 20px;
  }

  .floating-label {
    top: 12px !important;
  }

  input:not([pnkvalue=""])~.floating-label {

    top: 0px !important;

  }

  .lead-entry-main {
    background-color: #fff;
    padding: 16px 0px;
  }

  .personal-div {
    padding-top: 24px;
  }

  .le-nex-div {
    margin-top: 16px;
  }

  .crm-filter-container {
    width: 100%;
  }

  .le-section {
    background-color: #f7f8fc;
  }

  .mob-filter-container {
    width: 90%;
    margin: 0 auto;
  }

  .personal-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .le-mob-dow-arrow {
    color: rgba(0, 0, 0, 0.72);
    font-size: 14px !important;
    cursor: pointer;
  }
}
@media (max-width: 575.98px) and (min-width: 200px) {
  .mob-container {
    width: 90%;
    margin: 0 auto;
  }
  .lead-img-browse-label{
    font-size: 18px !important;
  }
  .lead-img-text-head{
    font-size: 14px !important;
  }
  .le-mob-header {
    height: 54px;
    background-color: #ecedf3;
    width: 100%;
    display: flex !important;
    align-items: center;
  }

  .le-mob-header-plus-btn {
    width: 47px;
    height: 38px;
    border-radius: 4px !important;
    border: solid 1px #6e9e75 !important;
    background-color: #ffffff !important;
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    cursor: pointer !important;
    letter-spacing: normal;
    color: #6e9e75 !important;

  }

  .le-cancel,
  .le-save {
    width: 86px !important;
    height: 38px !important;
    font-size: 14px !important;
    font-weight: 300 !important;
  }

  .align-center {
    display: flex !important;
    align-items: center !important;
  }

  .form-heading {
    font-size: 20px;
  }

  .floating-label {
    top: 12px !important;
  }

  input:not([pnkvalue=""])~.floating-label {

    top: 0px !important;

  }

  .lead-entry-main {
    background-color: #fff;
    padding: 16px 0px;
  }

  .personal-div {
    padding-top: 24px;
  }

  .le-nex-div {
    margin-top: 16px;
  }

  .crm-filter-container {
    width: 100%;
  }

  .le-section {
    background-color: #f7f8fc;
  }

  .mob-filter-container {
    width: 90%;
    margin: 0 auto;
  }

  .personal-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .le-mob-dow-arrow {
    color: rgba(0, 0, 0, 0.72);
    font-size: 14px !important;
    cursor: pointer;
  }
}

/* End */
/* ***************************End********************************** */
/* ***************************Login********************************** */
.login-main-div {
  height: 100vh;
  width: 100%;
  background-color: #ffffff;
}

/* .login-logo{
  background: url(../images/Final_logo_Urban_Roots_bold.svg) right top no-repeat;
  height: 120px;

} */
.logo-login-action {
  font-size: 32px !important;
  color: #000000;
  cursor: pointer;
}

.logo-div {
  padding: 40px;
  height: 35%;
}

.div-logo-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
  margin-bottom: 15%;
}

.div-logo-main1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 40px;
  margin-bottom: 15%;
}

.form-div {
  /* position: absolute;
  top: 40%; */
  width: 100%;
}

.login-filter-container {
  width: 75%;
  margin: 0 auto;
}

.login-heading {
  font-family: "Proxima Nova", sans-serif;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 36px;
}

.login-form-controls {
  width: 100%;
  height: 56px;
  border-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.34);
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
  font-family: "Proxima Nova", sans-serif !important;

}

.form-ctrl-div {
  margin-bottom: 16px;
}

.forgot-pass-link {
  font-family: ProximaNova;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #4a90e2;
  font-family: "Proxima Nova", sans-serif;
  float: right;
}

.log-btn-div {
  margin-top: 64px;
}

.login-btn {
  height: 52px;
  border-radius: 8px !important;
  background-color: #6e9e75 !important;
  width: 100%;
  font-family: "Proxima Nova", sans-serif;
  font-size: 20px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff !important;
  border: 0px !important;
  text-transform: uppercase;
}

.log-left-img {
  background: url(../images/Login-Bg.png) center center no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;

}

.forgot-pass-desc {
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
}

.error-feedback {
  color: #d0021b;
  padding-bottom: 10px;
  padding-top: 10px;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .log-form-col {
    position: absolute !important;
    background: white;
    width: 90% !important;
    margin-left: 8%;
    top: 20%;
    border-radius: 8px;
  }

  .log-padding-adj {
    padding: 16px;

  }

  .div-logo-main1 {
    display: none !important;
  }

  .login-filter-container {
    width: 100%;
    margin: 0 auto;
  }

  .login-heading {
    font-size: 32px;
  }

  .login-form-controls {
    height: 48px !important;
    font-size: 16px !important;
  }

  .form-ctrl-div {
    margin-bottom: 30px;
  }

  .login-btn {
    height: 48px !important;
    font-size: 20px !important;
  }

  .forgot-pass-desc {
    font-size: 12px;
  }

  .logo-login-action {
    font-size: 20px !important;
  }

  .div-logo-main {
    margin-bottom: 6%;
  }
}
@media (max-width: 575.98px) and (min-width: 200px) {
  .log-form-col {
    position: absolute !important;
    background: white;
    width: 90% !important;
    margin-left: 8%;
    top: 20%;
    border-radius: 8px;
  }

  .log-padding-adj {
    padding: 16px;

  }

  .div-logo-main1 {
    display: none !important;
  }

  .login-filter-container {
    width: 100%;
    margin: 0 auto;
  }

  .login-heading {
    font-size: 32px;
  }

  .login-form-controls {
    height: 48px !important;
    font-size: 16px !important;
  }

  .form-ctrl-div {
    margin-bottom: 30px;
  }

  .login-btn {
    height: 48px !important;
    font-size: 20px !important;
  }

  .forgot-pass-desc {
    font-size: 12px;
  }

  .logo-login-action {
    font-size: 20px !important;
  }

  .div-logo-main {
    margin-bottom: 6%;
  }
}

/* ***************************End********************************** */
/* ***************************User List********************************** */
.table-items-user {
  /* height: 68px; */
  border-radius: 8px;
  background-color: #ffffff;
  display: flex !important;
  align-items: center;
}

.table-data-user {
  padding: 17px 0px;
  width: 100%;
  word-break: break-all;
}

.text-overflow {
  height: 20px;
  overflow: hidden;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .table-items-user {
    height: max-content;
  }

  .table-data-user {
    padding: 10px 0px;
  }
}
@media (max-width: 575.98px) and (min-width: 200px) {
  .table-items-user {
    height: max-content;
  }

  .table-data-user {
    padding: 10px 0px;
  }
}

/* ***************************End********************************** */
/* ***************************User Entry********************************** */
.file-up-div {
  display: flex;
  width: 100%;
  height: 200px;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 8px;
  cursor: pointer;

}

input[type="file"] {
  display: none;
}

.custom-file-upload {

  text-align: center;
  cursor: pointer;

}

.date-picker-days i {
  height: 25px !important;
}

/* ***************************End********************************** */
/* ***************************Filter********************************** */
.filter-div {
  width: 75%;
}

.filter-main-div {
  width: 100%;
  z-index: 9999;
}

.filter-head-div {
  width: 100%;
  height: 48px;
  border-radius: 2px;
  background-color: #e4e4e4;
  display: flex;
  align-items: center;
}

/* The filter-container */
.filter-container {
  display: block;
  position: relative;
  padding-left: 25px;

  cursor: pointer;
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;

  letter-spacing: normal;
  color: #000000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.filter-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 25%;
  left: 3%;
  width: 12px;
  height: 12px;
  border: solid 1px #979797;
  background-color: #ffffff !important;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.filter-container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.filter-container input:checked~.checkmark {
  width: 12px;
  height: 12px;
  border: solid 1px #6e9e75;
  background-color: #ffffff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "/";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.filter-container input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.filter-container .checkmark:after {
  top: 1px;
  left: 1px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #6e9e75;
}

.filter-header {
  width: 100% !important;
}

.fil-section {
  /* padding: 24px 0px; */
}

.crm-container {
  width: 95%;
  margin: 0 auto;
}

.filter-body-div {
  width: 100%;
  border-radius: 2px;
  box-shadow: 0 2px 6px 0 rgba(135, 132, 132, 0.5);
  background-color: #f2f2f2;
}

.filter-connr {
  width: 95%;
  margin: 0 auto;
  padding: 24px 0px;
}

.fil-se-titile {
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}

.filter-form-cntrl {
  height: 46px !important;
  border-radius: 4px !important;
  border: solid 1px #979797 !important;
  background-color: #ffffff !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.4) !important;
  width: 100%;

}

.filter-pad-bottom {
  padding-bottom: 1px;
}

.margin-bottom-t-filter{
  margin-bottom: 5px !important;
}

.filter-btn-close {
  width: 82px !important;
  height: 40px !important;
  border-radius: 4px !important;
  border: solid 1px rgba(153, 153, 153, 0.34) !important;
  background-color: #ffffff !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000 !important;
  text-transform: uppercase;
}

.filter-btn-generate {
  width: 114px !important;
  height: 40px !important;
  border-radius: 4px !important;
  border: solid 1px rgba(153, 153, 153, 0.34) !important;
  background-color: #6e9e75 !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff !important;
}
.filter-btn-export {
  width: 150px !important;
  height: 40px !important;
  border-radius: 4px !important;
  border: solid 1px rgba(153, 153, 153, 0.34) !important;
  background-color: #6e9e75 !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff !important;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

/* ***************************End********************************** */
/* ***************************Header Filter********************************** */
@media (min-width: 576px) and (max-width: 767.98px) {
  .header-search-append-text {
    background-color: #fff !important
  }

  .header-search-bar {
    border-right: 0px !important;
  }

  .header-search-cancel {
    font-family: "Proxima Nova", sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000 !important;
  }

  .header-search-cancel:hover,
  .header-search-cancel:active,
  .header-search-cancel:focus {
    color: #000000 !important;
  }
}
@media (max-width: 575.98px) and (min-width: 200px) {
  .header-search-append-text {
    background-color: #fff !important
  }

  .header-search-bar {
    border-right: 0px !important;
  }

  .header-search-cancel {
    font-family: "Proxima Nova", sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000000 !important;
  }

  .header-search-cancel:hover,
  .header-search-cancel:active,
  .header-search-cancel:focus {
    color: #000000 !important;
  }
}

/* ***************************End********************************** */
/* ***************************Profile List********************************** */
.lead-table-data-user {
  margin-bottom: 8px;
}

.profile-search-box {
  width: 266px;
  height: 40px !important;
  border-radius: 4px !important;
  border: solid 1px #d6d6d6 !important;
  background-color: #ffffff !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.34) !important;
  font-family: "Proxima Nova", sans-serif;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0px !important;

}

.profile-search-text {
  border-radius: 4px !important;
  background-color: #f2f2f2 !important;
}

.profile-search-icon {
  font-size: 16px !important;
  color: #b7b4b4 !important;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .lead-table-div {
    padding: 0px !important;
  }

  .mob-row-align {
    display: flex;
    align-items: center;
    height: 60px;
  }

  .lead-table-data {
    padding-bottom: 8px;
    padding-top: 0px !important;
  }
}
@media (max-width: 575.98px) and (min-width: 200px) {
  .lead-table-div {
    padding: 0px !important;
  }

  .mob-row-align {
    display: flex;
    align-items: center;
    height: 60px;
  }

  .lead-table-data {
    padding-bottom: 8px;
    padding-top: 0px !important;
  }
}

/* ***************************End********************************** */
/* ***************************Profile Entry********************************** */
.profile-entry-modal .modal-content {
  border-radius: 2px;
  box-shadow: 0 2px 6px 0 rgba(135, 132, 132, 0.5);
  background-color: #ffffff;
}

.width_100 {
  width: 100%
}

.profile-entry-header {
  background: #f2f2f2;
  height: 48px;
}

.profile-entry-title {
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;

}

.profile-entry-label {
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}

.profile-entry-controls {
  height: 46px !important;
  border-radius: 4px !important;
  border: solid 1px #979797 !important;
  background-color: #ffffff !important;
  font-family: "Proxima Nova", sans-serif;
  width: 100%;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.4);
}

.profile-entry-text-area {

  border-radius: 4px !important;
  border: solid 1px #979797 !important;
  background-color: #ffffff !important;
  font-family: "Proxima Nova", sans-serif;
  width: 100%;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.4);
}

._3vt7_Mh4hRCFbp__dFqBCI {
  min-height: 46px !important;
  border-radius: 4px !important;
  border: solid 1px #979797 !important;
  background-color: #ffffff !important;
  font-family: "Proxima Nova", sans-serif;
  width: 100%;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.4);

}

._2iA8p44d0WZ-WqRBGcAuEV {
  border: 0px solid #cccccc !important;
  border-radius: 4px;
  padding: 5px;
  min-height: 100% !important;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background: url('../images/angle-arrow-down.png') no-repeat center right;
  background-repeat: no-repeat;
  background-position-x: 98%;

}

.pe-close {
  position: absolute;
  top: 2.5%;
  right: 5%;
  color: rgba(0, 0, 0, 0.4);
  font-size: 20px !important;
  cursor: pointer;
}

.selectSearch-profile {
  border: solid 1px #d6d6d6;
  border-left: 0px;
  background-color: #ffffff;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;

  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

.profile-entry-save-btn {
  width: 74px !important;
  height: 40px !important;
  border-radius: 4px !important;
  border: solid 1px rgba(153, 153, 153, 0.34) !important;
  background-color: #6e9e75 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff !important;
  font-family: "Proxima Nova", sans-serif;

}

.profile-entry-cancel-btn {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000 !important;
  font-family: "Proxima Nova", sans-serif;
  width: 94px !important;
  height: 40px !important;
  border-radius: 4px !important;
  border: solid 1px rgba(153, 153, 153, 0.34) !important;
  background-color: #ffffff !important;
}

.profile-btn-div {
  padding-top: 32px;
  padding-bottom: 32px;
}

.edit-btn-div {
  /* justify-content: center;
  display: flex; */
  padding: 20px;
  text-align: center;
}

/* .select-class{
  content:"\f078";
  font-family:'FontAwesome';
  position: absolute;
  color: #04838a;
  top: 1rem;
  right: 0.5rem;
  z-index: 5;
  pointer-events: none;
} */
/* ._3vt7_Mh4hRCFbp__dFqBCI input{
  -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 100%;
} */
.pagination-div-profile {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  align-items: center;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .selectSearch-profile {
    width: 65%;
    border: 0px;
  }

  .profile-entry-modal .modal-content {
    border-radius: 8px !important;
    width: 97% !important;
  }
}
@media (max-width: 575.98px) and (min-width: 200px) {

  .selectSearch-profile {
    width:50%;
    border: 0px;
  }

  .profile-entry-modal .modal-content {
    border-radius: 8px !important;
    width: 97% !important;
  }
}

/* ***************************End********************************** */
/* ***************************Cancel Modal********************************** */
.cancel-modal-text {
  font-family: "Proxima Nova", sans-serif;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}

.cancel-modal-btn-div {

  padding-top: 52px;
  display: flex;
  justify-content: flex-end;
}

.cancel-modal-save {
  width: 78px !important;
  height: 40px !important;
  border-radius: 4px !important;
  border: solid 1px rgba(153, 153, 153, 0.34) !important;
  background-color: #6e9e75 !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff !important;
  text-transform: uppercase;
}

.cancel-modal-cancel-btn {
  width: 72px !important;
  height: 40px !important;
  border-radius: 4px !important;
  border: solid 1px rgba(153, 153, 153, 0.34) !important;
  background-color: #ffffff !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000 !important;
  text-transform: uppercase;
}

.cancel-modal .modal-content {
  border-radius: 4px !important;
  background-color: #ffffff;
  padding: 60px;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .cancel-modal-text {
    font-size: 24px;
  }

  .cancel-modal .modal-content {
    padding: 32px 26px !important
  }

  .cancel-modal-btn-div {
    padding-top: 32px !important;
  }
}
@media (max-width: 575.98px) and (min-width: 200px) {
  .cancel-modal-text {
    font-size: 24px;
  }

  .cancel-modal .modal-content {
    padding: 32px 26px !important
  }

  .cancel-modal-btn-div {
    padding-top: 32px !important;
  }
}

/* ***************************End********************************** */
/* ***************************Bulk Modal********************************** */
.bulk-modal-text {
  font-family: "Proxima Nova", sans-serif;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #000000;
}

.bulk-modal .modal-content {
  border-radius: 4px !important;
  background-color: #ffffff;
  padding: 56px;
}

.bulk-modal-file-up-div {
  padding-top: 24px;
  padding-bottom: 32px;
}

.bulk-modal-file .custom-file-label {

  height: 48px;
  border-radius: 4px;
  border: solid 1px #979797;
  background-color: #ffffff;
  display: flex;
  align-items: center;
}

.bulk-modal-file .custom-file-input:lang(en)~.custom-file-label::after {
  content: "Attach";
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
}

.bulk-modal-file .custom-file-label::after {
  background-color: rgba(18, 35, 52, 0.56);
  height: 100%;
  display: flex;
  align-items: center;

}

.bulk-modal-btn-div {
  display: flex;
  justify-content: flex-end;
}

.bulk-modal-cancel-btn {
  width: 96px !important;
  height: 40px !important;
  border-radius: 4px !important;
  border: solid 1px rgba(153, 153, 153, 0.34) !important;
  background-color: #ffffff !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000 !important;
  text-transform: uppercase !important;
}

/* ***************************End********************************** */
/* ***************************Booking List********************************** */
.booking-album-icon {
  height: 16px;
  cursor: pointer;
}

.booking-album-header {
  background: #f2f2f2;
  height: 48px;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.booking-photolits {
  padding: 40px;
}

.photolist-img {
  width: 100%;
  height: 166px;
}

.photo-col {
  margin-bottom: 16px;
}

.photo-album-download-icon {
  font-size: 10px !important;
}

.download-btn-div {
  position: absolute;
  right: 25px;
  bottom: 8px;
  width: 21px;
  height: 19px;
  border-radius: 4px;
  background-color: #d8d8d8;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .photolist-img {
    height: 132px;
  }

  .booking-photolits {
    padding: 24px 16px;
  }
}
@media (max-width: 575.98px) and (min-width: 200px) {
  .photolist-img {
    height: 132px;
  }

  .booking-photolits {
    padding: 24px 16px;
  }
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #e9ecef !important;
  opacity: 1;
}

/* ***************************End********************************** */
/* Toast */
.toast-header-success {
  display: flex;
  align-items: center;
  padding: .25rem .75rem;
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, .05);
  color: #fff !important;
  background-color: #6E9E79 !important;
}

.toast-header-error {
  display: flex;
  align-items: center;
  padding: .25rem .75rem;
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, .05);
  color: #fff !important;
  background-color: #E22A40 !important;
}


/* LABEL COLOR - PR */

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

a.label:hover,
a.label:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.label:empty {
  display: none;
}

.btn .label {
  position: relative;
  top: -1px;
}

.label-default {
  background-color: #777;
}

.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #5e5e5e;
}

.label-primary {
  background-color: #337ab7;
}

.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #286090;
}

.label-success {
  background-color: #5cb85c;
}

.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #449d44;
}

.label-info {
  background-color: #5bc0de;
}

.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #31b0d5;
}

.label-warning {
  background-color: #f0ad4e;
}

.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #ec971f;
}

.label-danger {
  background-color: #d9534f;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #c9302c;
}

/* Login Aler */
.loginAlertDiv {
  position: absolute;
  right: 1%;
  bottom: 2%;
}

/* ***************************Entry Page********************************** */
.entry-foom-main-div {
  background-color: #F7F8FC;
  width: 100vw;
  height: 100vh;
}
.entry-row{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.entry-container {
  width: 80%;
  margin: 0 auto;
  padding-top: 52px;
}

.entry-btn-pills {
  display: grid;
  cursor: pointer;
}

.entry-btn {
  height: 60px;
  width: 90%;
  border-radius: 0 0 4px 4px !important;
  background-color: #FFFFFF !important;
  color: #000000 !important;
  font-family: "Proxima Nova", sans-serif !important;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 8px;
}

.entry-form-div {
  /* height: 402px; */
  width: 90%;
  border-radius: 4px;
  background-color: #FFFFFF;
}

.entry-form-title {
  height: 48px;
  width: 100%;
  border-radius: 4px 4px 0 0;
  background-color: #ECEDF3;
  color: #000000;
  font-family: "Proxima Nova", sans-serif;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.entry-form {
  padding: 40px 62px;
}

.entry-form-label {
  color: #283048;
  font-family: "Proxima Nova", sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.entry-form-cntrl {
  box-sizing: border-box !important;
  height: 44px !important;
  width: 100%;
  border: 1px solid rgba(151, 151, 151, 0.34) !important;
  border-radius: 2px !important;
  background-color: #FFFFFF !important;
  color: #283048 !important;
  font-family: "Proxima Nova", sans-serif !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  letter-spacing: 0;
  line-height: 14px;
}

.entry-chk label {
  color: #4A90E2;
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
}

.entry-add-btn {
  height: 44px !important;
  width: 100%;
  border-radius: 4px !important;
  background-color: #6E9E75 !important;
  color: #FFFFFF !important;
  font-family: "Proxima Nova", sans-serif !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 20px;
}

.entry-btn.active {
  background: #FFFFFF;
  border: 0px !important;
  border-bottom: 4px solid #6E9E75 !important;
}

.entry-btn:active,
.entry-btn:focus {
  background: #FFFFFF;
  border: 0px !important;
  border-bottom: 4px solid #6E9E75 !important;
}

.errorMessage {
  font-size: 14px;
  font-family: "Proxima Nova", sans-serif !important;
  color: red
}

.entry-foom-main-div-mob {
  display: none !important;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .entry-foom-main-div {
    display: none !important;
  }
  .entry-foom-main-div-mob {
    display: block !important;
    background-color: #F7F8FC;
    width: 100vw;
    height: 100vh;
  }

  .entry-btn.active {
    background: #FFFFFF;
    border: 0px !important;
    border-bottom: 0px solid #6E9E75 !important;
  }

  .entry-container {
    width: 100% !important;
  }

  .entry-btn-mobile {
    width: 100% !important;
    height: 68px !important;
    font-size: 20px !important;
    margin-bottom: 16px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
  }

  .entry-mob-title {
    color: #000000;
    font-family: "Proxima Nova", sans-serif;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .entry-form-div {
    width: 100% !important;
    height: auto !important;
  }

  .form-col-entry {
    margin-top: -5%;
    margin-bottom: 16px;
  }

  .entry-form-label {
    font-size: 16px !important;
  }

  .entry-form {
    padding: 24px 16px;
  }

  .entry-add-btn {
    font-size: 18px !important;
    height: 42px !important;
  }
}
@media (max-width: 575.98px) and (min-width: 200px) {
  .entry-foom-main-div {
    display: none !important;
  }
  .entry-foom-main-div-mob {
    display: block !important;
    background-color: #F7F8FC;
    width: 100vw;
    height: 100vh;
  }

  .entry-btn.active {
    background: #FFFFFF;
    border: 0px !important;
    border-bottom: 0px solid #6E9E75 !important;
  }

  .entry-container {
    width: 100% !important;
  }

  .entry-btn-mobile {
    width: 100% !important;
    height: 68px !important;
    font-size: 20px !important;
    margin-bottom: 16px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
  }

  .entry-mob-title {
    color: #000000;
    font-family: "Proxima Nova", sans-serif;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .entry-form-div {
    width: 100% !important;
    height: auto !important;
  }

  .form-col-entry {
    margin-top: -5%;
    margin-bottom: 16px;
  }

  .entry-form-label {
    font-size: 16px !important;
  }

  .entry-form {
    padding: 24px 16px;
  }

  .entry-add-btn {
    font-size: 18px !important;
    height: 42px !important;
  }
}

/* ***************************End********************************** */
/* ***************************Entry List********************************** */
.entry-option-select {
  height: 40px !important;
  width: 100% !important;
  border-radius: 4px !important;
  background-color: #FFFFFF;
  color: #000000 !important;
  font-family: "Proxima Nova", sans-serif !important;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 16px;
}

.header-ddl {
  box-sizing: border-box !important;
  height: 40px !important;
  width: 15% !important;
  border: 1px solid #EBEBEB !important;
  background-color: #FFFFFF !important;
  color: #000000 !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 20px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 20px;
  margin: 20px !important;
}

.status-entry-list-error {
  width: 30%;
  background: #e28686;
  text-align: center;
  border-radius: 4px;
}

.status-entry-list-active {
  width: 30%;
  background: #5D8614;
  text-align: center;
  border-radius: 4px;
}

.reload-img {
  cursor: pointer;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .header-ddl {
    width: 40% !important;
    font-size: 14px !important;
  }
  .action-div{
   height: max-content !important;
   padding-top: 10px;
  }
}
@media (max-width: 575.98px) and (min-width: 200px) {
  .header-ddl {
    width: 40% !important;
    font-size: 14px !important;
  }

    .action-div{
      height: max-content !important;
      padding-top: 10px;
     }
  
}

/* ***************************End********************************** */
/* ***************************Remonder********************************** */
.remainder-modal {}

.callnow-img {
  width: 200px;
}

.rem-img-div {
  width: 100% !important;
  display: flex;
  justify-content: center;
}

.rem-modal-head-text {
  color: #000000;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.rem-modal-sub-text {
  color: #000000;
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.rem-modal-btn-div {
  width: 100% !important;
  display: flex;
  justify-content: center;
  padding-top: 52px;
}

.rem-modal-cancel-btn {
  box-sizing: border-box !important;
  height: 44px !important;
  width: 140px !important;
  border: 1px solid rgba(115, 114, 114, 0.34) !important;
  border-radius: 4px !important;
  background-color: #FFFFFF;
  color: #050505 !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 20px !important;
  font-weight: 300 !important;
  letter-spacing: 0;
  line-height: 20px;
  text-transform: uppercase;
}

.rem-modal-save {
  height: 44px !important;
  width: 140px !important;
  border-radius: 4px !important;
  background-color: #6E9E75 !important;
  color: #FFFFFF !important;
  font-family: "Proxima Nova", sans-serif;
  font-size: 20px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 20px;
  text-transform: uppercase;
}

/* ***************************End********************************** */
/* *************************** Source Entry ********************************** */
.source-img-div {
  box-sizing: border-box;
  height: 116px;
  width: 214px;
  border: 1px solid #979797;
  border-radius: 2px;
  background-color: #FFFFFF;
}

.source-img-elements {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.source-tit-text {
  font-family: "Proxima Nova", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
  text-align: center;
}

.source-sub-text {
  color: #283048;
  font-family: "Proxima Nova", sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
}

.source-img-file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.source-ctrl-select {
  width: 42% !important;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .profile-entry-modal {
    margin-left: 1.5% !important;
  }
}
@media (max-width: 575.98px) and (min-width: 200px) {
  .profile-entry-modal {
    margin-left: 1.5% !important;
  }
}

.showPreviewImg {
  width: 80% !important;
  height: 60% !important;
}

/* .previewDiv{
  height: 250px;
} */
/* ***************************End********************************** */

.lead-source-img-div{
box-sizing: border-box;
width: 50%;
border: 1px solid #979797;
border-radius: 2px;
background-color: #FFFFFF;
}
/* ***************************Reports********************************** */
.header-select{
  box-sizing: border-box;
  height: 40px;
  width: 220px !important;
  border: 1px solid #EBEBEB !important;
  background-color: #FFFFFF !important;
  color: #000000 !important;
  font-family: "Proxima Nova",sans-serif !important;
  font-size: 20px !important;
  font-weight: 600 !important; 
  letter-spacing: 0;
  line-height: 20px;
    position: absolute;
    top: 17px;
    left: 8%;
}
.header-select:active,.header-select:focus{
  box-shadow: 0 0 0 0 rgba(216,217,219,.5) !important;
}
.report-btn-div{
  padding-left: 100px;
  padding-top: 20px;
  width: max-content;
  display: flex;
  align-items: center;
}
.report-btn-group{
  border-bottom: 1px solid #CBCBCB;
  height: 50px;
}
.report-btn{
  background: transparent;
    border: 0px !important;
    color: #000000 !important;
    font-family: "Proxima Nova",sans-serif !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    letter-spacing: 0;
    line-height: 16px !important;
}
.report-btn:active,.report-btn:focus{
  background: transparent !important;
  border:0px !important;
  border-bottom: 4px solid #6E9E75 !important;
  box-shadow: 0 0 0 0 rgba(216,217,219,.5) !important;

}
.report-btn.active{
  background: transparent !important;
  border:0px !important;
  border-bottom: 4px solid #6E9E75 !important;
  box-shadow: 0 0 0 0 rgba(216,217,219,.5) !important;
}
.custom-date-div{
padding-left: 24px;
display: flex;
    align-items: center;
}
.custom-date-picker{
  box-sizing: border-box;
  height: 36px;
  width: 166px;
  border: 1px solid #979797;
  background-color: #FFFFFF;
}
.dashboard-option-select{
  height: 40px !important;
  width: 142px !important;
  border-radius: 4px !important;
  background-color: #FFFFFF !important;
  color: #000000 !important;
  font-family: "Proxima Nova",sans-serif !important;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 16px;
}
.custom-apply-btn{
  height: 36px !important;
  width: 90px !important;
  border-radius: 4px !important;
  background-color: #6E9E75 !important;
  color: #FFFFFF !important;
  font-family: "Proxima Nova",sans-serif !important;
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 16px;
}
/* .inside-sales-dead{
  height: 40px !important;
  width: 99px !important;
  border-radius: 4px !important;
  background-color: #6E9E75 !important;
  color: #FFFFFF !important;
  font-family: "Proxima Nova",sans-serif !important;
  font-size: 16px !important;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  border: 0px !important;
} */

.inside-sales-btn{
  height: 40px !important;
  width: 100% !important;
  border-radius: 4px !important;
  background-color: #FFFFFF !important;
  color: #000000 !important;
  font-family: "Proxima Nova",sans-serif !important;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 16px;
  border: 0px !important;
}
.inside-sales-btn.active{
  background-color: #6E9E75 !important;
  color: #FFFFFF !important;
  font-weight: bold !important;
  box-shadow: 0 0 0 0 rgba(38,143,255,.5) !important;
}
.dashboard-margin-5{
  margin-left: 5px;
  width: 50%;
  overflow: hidden;
}
.text-end{
  text-align: end;
}
.flex-display{
  display: flex;
}
.dashbord-view-more-icon{
  cursor: pointer;
}
.view-data-label{
  color: #000000;
  font-family: "Proxima Nova",sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}
.view-data-data{
  color: #000000;
  font-family: "Proxima Nova",sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
}
.view-data-div{
  margin-bottom: 24px;
}
.modal-header-close{
  font-size: 14px;
  color: #000;
  position: absolute;
  right: 5%;
  top: -25px;
  cursor: pointer;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .header-select
  {
  height: 30px !important;
  width: 140px !important;
  font-size: 14px !important;
  left: 10%;
  }
  .dashboard-option-select
  {
    height: 30px !important;
    width: 100% !important;
    font-size: 12px !important;
    margin-left: 10%;
  }
  .mob-action-div{
    height: 56px;
    width: 100%;
    background-color: #F7F8FC;
  }
  .mob-row-color{
    height: 60px;
    width: 100vw;
    background-color: #ECEDF3;
    margin-left: -5% !important;
  }
  .report-btn-div{
    display: none;
  }
  .inside-sales-btn{
    height: 32px !important;
    /* width: 78px !important; */
    font-size: 12px !important;
  }
  .inside-sales-pending{
    height: 32px !important;
    width: 99px !important;
    font-size: 14px !important;
  }
}
@media (max-width: 575.98px) and (min-width: 200px) {
  .header-select
  {
  height: 30px !important;
  width: 140px !important;
  font-size: 14px !important;
  left: 12%;
  }
  .dashboard-option-select
  {
    height: 30px !important;
    width: 100% !important;
    font-size: 12px !important;
    margin-left: 10%;
  }
  .mob-action-div{
    height: max-content;
    width: 100%;
    background-color: #F7F8FC;
    padding-top: 15px;
    padding-bottom: 15px;

  }
  .padding-form{
    padding-bottom: 10px;
  }
  .fontAction{
    font-size: 10px !important;
  }
  .paddingReport{
    padding: 10px;
  }
  .mob-row-color{
    height: 60px;
    width: 100vw;
    background-color: #ECEDF3;
    margin-left: -5% !important;
  }
  .report-btn-div{
    display: none;
  }
  .inside-sales-btn{
    height: 32px !important;
    /* width: 78px !important; */
    font-size: 12px !important;
  }
  .inside-sales-pending{
    height: 32px !important;
    width: 99px !important;
    font-size: 14px !important;
  }
}
/* ***************************End********************************** */
.colorBlueViolet{
  background: blueviolet;
}
.colorBrown{
  background: brown;
}
.colorDesignHanded{
  background: crimson;
}
.colorProposalUuploaded{
  background:rgb(134, 53, 110);
}
.colorProposalSent{
  background: rgb(48, 163, 138);
}
.colorBookingAmount{
  background: rgb(79, 117, 18);
}
.colorFollowUp{
  background: rgb(17, 17, 212);
}
.colorProjectClose{
  background: rgba(165, 10, 36, 0.493);
}
.colorCallback{
  background: rgb(31, 144, 189);
}
.colorHCProgress{
  background:burlywood
}
.colorDefault{
  background: darkgray;
  color: black;
}
.statusDiv{
  height: 20px;
  overflow: hidden;
}
.show-date-time{
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-top: 2%;
}
/* Tele caller Dashboard */
.tele-caller-db-head{
  color: #000000;
  font-family: "Proxima Nova",sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
    top: 2rem;

}
.telecalling-stats{
  box-sizing: border-box;
  height: 70px;
  width: 100%;
  border: 1px solid #DBD9D9;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}
.tele-calling-db-head{
  color: #000000;
  font-family: "Proxima Nova",sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
}
.tele-calling-db-count{
  color: #000000;
  font-family: "Proxima Nova",sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 15%;
}
.date-time-hc-pm{
  text-align: left;
  padding-top: 12px;
  font-size: 14px;
  font-weight: 600;
  color: black;
}
.desc-overflow{
  height: 35px;
  overflow: hidden;
}
@media (max-width: 575.98px) and (min-width: 200px) {
  .tele-caller-db-head{
    left: 10% !important;
  }
  .dashboard-option-select-mob{
    width: 90% !important;
  }
  .db-row{
    padding-top: 5% !important;
  }
  .db-col{
    margin-bottom: 8px !important;
  }
  #custom-date-picker .modal-content{
    width: 94%;
    margin-left: 2%;
  }
  .custom-apply-btn-modal{
    height: 36px !important;
    width: 100% !important;
    border-radius: 4px !important;
    background-color: #6E9E75 !important;
    color: #FFFFFF !important;
    font-family: "Proxima Nova",sans-serif !important;
    font-size: 16px !important;
    font-weight: bold !important;
    letter-spacing: 0;
    line-height: 16px;
  }
  .custom-center{
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
.show-patch-details{
  width: 100%;
  margin: 15px 0px;
  height: auto;
  background: burlywood;
}

/* New Changes */
.lead-list-footer-count{
  color:rgb(212, 81, 92);
  font-size: 16px;
  font-family: "Proxima Nova",sans-serif !important;
  position: absolute;
    left: 5%;

 
}
.faq-service-card
{
  margin-bottom: 15px;
}
.lead-entry-back-button{
  position: absolute;
  top: -1.6%;
  left: 6px;
  cursor: pointer;
}

.faq-service-card-head{
  box-sizing: border-box;
  border-bottom: 2px solid #f2f2f2;
 
  background-color: #FFFFFF !important;
  width: 90%;
  margin: 0 auto;
  color: #000 !important;
  font-family: "Proxima Nova",sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  padding: 32px;
  display: flex;
    justify-content: space-between;
    text-transform: none;

}
.faq-card-body.card-body{
  color: #36454f !important;
  font-family: "Proxima Nova",sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 26px;
  width: 90%;
  margin: 0 auto;
  text-transform: none;
}
.control-icons{
  height: 24px;
  width: 24px;
  opacity: 0.54;
}
.table-head-main{
  display: table;
  border: 1px solid #f4f4f4;
  /* height: 100px; */
  padding-left: 16px;
  padding-right: 16px;
  /* align-items: center; */
  min-width: 250px;
}
.table-main{
  display: flex;
  overflow-x: scroll;
}
.table-body{
  border: 1px solid #353535;
  height: max-content;
  padding-bottom: 8px;
  padding-top: 8px;
  margin-bottom: 12px;
  min-height: 120px;
}
.meeting-leadowner{
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}
.subHeadHC{
  font-weight: bold;
  font-size: 18px;
  color: #000000;
}